<template>
    <div id="header">
        <header id="quiz_nav">
            <ul>
                <li v-bind:key="item.step" v-for="(item, index) in data.steps" :class="activeStep === index ? 'active' : ''">

                </li>
            </ul>
            <div class="steps">
                <span class="name">{{data.steps[activeStep].name}}:</span> {{activeQuestion+1}} / {{data.steps[activeStep].questions.length}}
            </div>
            <div class="question-step">
                {{activeQuestion+1}}. Kérdés
            </div>
        </header>
    </div>
</template>

<script>
    export default {
        name: 'QuizHeader',
        computed : {
            data () {
                return this.$store.state.data;
            },
            activeQuestion () {
                return this.$store.state.current_question;
            },
            activeStep() {
                return this.$store.state.current_step;
            },
            is_end() {
                return this.$store.state.is_end;
            },
            modal() {
                return this.$store.state.modal;
            },
            question(){
                return this.data.steps[this.activeStep].questions[this.activeQuestion];
            }
        },
    }
</script>
