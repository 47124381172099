<template>
    <section id="LCD" class="screen" style="background-image: url('https://fustmenteskalkulator.hu/img/backgrounds/02_KOZLEKEDES/KarbonKalkulator_fotok_02_K2_Kozossegi_kozl.jpg');">
        <div class="lcd-container" >

            <header>
                <span class="img-container">
                    <img src="../assets/fustmentes_logo.png" alt="" class="logo">
                </span>
                <h1>Csatlakozz hozzánk  <span> és dobjunk le 100 kilót!</span></h1>

            </header>

            <span class="web">www.dobjle<span>100</span>at.hu</span>

            <div class="inner">
                <div class="panel bg-white">
                    {{csv_lines}}
                </div>
            </div>



        </div>

    </section>
</template>

<script>

    export default {
        name: 'Lcd',
        components: {

        },
        data(){
            return {
                count: 0,
                myFileUri : "../../public/datas.csv"
            }
        },
        mounted() {
            this.csv();
            this.interval = setInterval(() => this.csv(), 60 * 1000);

        },
        computed : {
            data () {
                return this.$store.state.data;
            },
            csv_lines () {
                return this.$store.state.count;
            }
        },
        methods: {
            csv(){
                this.$store.dispatch('check_csv');
            }
        }
    }
</script>
