<template>
    <div id="desc" :class="{ active : show_modal }">
        <div class="desc-container">
            <div class="close" @click="toggle_modal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
            <div class="text">
                {{question.body}}

                <div class="options" v-if="question.options.length > 0">
                    <strong>Megtakarítási lehetőség:</strong>
                    <div v-bind:key="index" class="option" v-for="(opt, index) in question.options">
                        <div v-bind:key="i" class="option" v-for="(obj, i) in opt">
                            {{i}} <strong>{{obj}}</strong>
                        </div>
                    </div>
                </div>

                <div class="tip" v-if="question.tip.length > 0">
                    <strong>Tipp:</strong>
                    <div class="tipp-text">
                        {{question.tip}}
                    </div>
                </div>

                <div class="asterisk" v-if="question.hasOwnProperty('asterisk')">
                    * {{question.asterisk}}
                </div>


            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'SectionDescription',
        computed : {
            data () {
                return this.$store.state.data;
            },
            activeQuestion () {
                return this.$store.state.current_question;
            },
            activeStep() {
                return this.$store.state.current_step;
            },
            is_end() {
                return this.$store.state.is_end;
            },
            show_modal() {
                return this.$store.state.modal;
            },
            question(){
                return this.data.steps[this.activeStep].questions[this.activeQuestion];
            }
        },
        methods: {
            toggle_modal(){
                this.$store.dispatch('toggle_modal');
            }
        }
    }
</script>
