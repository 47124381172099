<template>
    <div id="final" class="screen">

        <div class="inner">

            <header class="container">
                <img src="../assets/fustmentes_logo.png" alt="" class="logo">
                <h1>Füstmentes <span>Kalkulátor</span></h1>
            </header>


               <div class="container">
                   <div class="inner">
                       <h1>Impresszum</h1>

                       <p><strong>Cégnév:</strong> Philip Morris Magyarország Kft.
                       </p><p><strong>Cím:</strong> 1085 Budapest, Kálvin tér 12.
                   </p><p><strong>Telefon:</strong> 36 20 884 4400
                   </p><p><strong>E-mail:</strong> info@fustmentes.hu
                   </p><p><strong>Internet:</strong><a href="www.fustmentes.hu" target="_blank">www.fustmentes.hu</a><br>
                       <a href="https://fustmenteskalkulator.hu" target="_blank">www.fustmenteskalkulator.hu</a><br>
                       <a href="htps://dobjle100at.hu" target="_blank">www.dobjle100at.hu</a></p><p><strong>A céget bejegyző hatóság:</strong> Fővárosi Törvényszék Cégbírósága

                   </p><p><strong>Cégjegyzékszám:</strong> 01-09-281021
                   </p><p><strong>Adószám:</strong> 10624225-2-44
                   </p><p><strong>Hatósági engedély:</strong> -
                   </p><p><strong>Kamara:</strong> Budapesti Kereskedelmi és Iparkamara (BKIK)
                   </p><p><strong>Tárhelyszolgáltató:</strong>
                       Magyar Hosting Kft. (H-1132 Budapest  Victor Hugo u. 18-22.)
                   </p>
                   </div>
               </div>


        </div>

    </div>
</template>
