<template>
    <div id="final" class="screen">

        <div class="inner">

            <header class="container">
                <img src="../assets/fustmentes_logo.png" alt="" class="logo">
                <h1>Füstmentes <span>Kalkulátor</span></h1>
            </header>

            <div class="final-container">
                <div class="col first">
                    <div style="padding: 30px 0; font-weight: bold; font-family: Helvetica, Arial, sans-serif; color: #00314a; font-size: 20px;">
                        Köszönjük! Ön volt a {{csv_lines}}. kitöltő.
                    </div>
                    <div class="carbon-footprint">
                        <span class="text ole">Az Ön széndioxid-kibocsátása</span>
                        <span class="fin">
                            <span class="num">{{tons}}</span>
                            <span class="metr"> tonna</span>
                        </span>



                    </div>

                    <div class="low score-txt" v-if="tons < 6">
                        <h2>Gratulálunk!</h2>
                        <p>Ön példás, fenntartható életmódot folytat, őrizze meg jó szokásait! További hasznos károsanyag-kibocsátási tippekért látogasson el az oldalunkra! </p>
                      <p style="display: inline-block; text-transform: none; color: #00314a; width: 100%; padding: 20px; text-align: center;">https://dobjle100at.hu/</p>
                      <span class="button  start-btn" @click="reset">
                        Vissza
                      </span>
                    </div>

                    <div class="med score-txt" v-if="tons >= 6 && tons <= 12">
                        <p>Az Ön széndioxid-kibocsátása az átlagosnál kisebb, de a fenntarthatóság szintjénél magasabb. Ha kíváncsi, hogyan csökkentheti károsanyag-kibocsátását, olvassa el hasznos tanácsainkat! </p>
                      <p style="display: inline-block; text-transform: none; color: #00314a; width: 100%; padding: 20px; text-align: center;">https://dobjle100at.hu/</p>
                      <span class="button  start-btn" @click="reset">
                        Vissza
                      </span>
                    </div>

                    <div class="high score-txt" v-if="tons > 12">
                        <h2>Figyelem!</h2>
                        <p>az Ön szén-dioxid kibocsátása nem csak a fenntartható szintnél, hanem a magyar átlagnál is magasabb. Kérjük, látogasson el az oldalunkra, ahol  számos kibocsátás csökkentési tippet és tanácsot talál!</p>
                      <p style="display: inline-block; text-transform: none; color: #00314a; width: 100%; padding: 20px; text-align: center;">https://dobjle100at.hu/</p>

                      <span class="button  start-btn" @click="reset">
                        Vissza
                      </span>

                    </div>



                    <div class="footprint-table">
                        <span class="world"></span>
                        <span class="hun"></span>
                        <span class="me"></span>
                        <img src="../assets/table.svg" alt="" class="table">
                    </div>


                </div>
                <div class="col second">
                    <img v-if="tons < 6" src="../assets/scale_small.svg" alt="" class="libra">
                    <img v-if="tons >= 6 && tons <= 12" src="../assets/scale_mid.svg" alt="" class="libra">
                    <img v-if="tons > 12" src="../assets/scale_big.svg" alt="" class="libra">
                </div>


            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'Final',
        data(){
          return {
              height: 0,
              count: 251,
          }
        },
        mounted() {
            this.csv();
            this.$nextTick(function () {

                let t = this.tons;
                let styler = setInterval(function(){

                    let bottom_ratio = 0.343009677419;
                    let height_ratio = 0.403225806452;
                    let table_height = document.querySelector('.table').offsetHeight;
                    let table_width = document.querySelector('.table').offsetWidth;
                    //console.log(table_width);

                    let foot = document.querySelector('.me');
                    let world = document.querySelector('.world');
                    let hun = document.querySelector('.hun');


                    let foot_bottom = table_height * bottom_ratio;
                    let tonheight = table_height * height_ratio / 20;


                    foot.style.bottom = foot_bottom + 'px';
                    world.style.bottom = foot_bottom + 'px';
                    hun.style.bottom = foot_bottom + 'px';

                    foot.style.height = t * tonheight + 'px';
                    world.style.height = 20 * tonheight + 'px';
                    hun.style.height = 12 * tonheight + 'px';

                    foot.style.left = table_width * 0.251572327044 + 'px';
                    world.style.left = table_width * 0.746855345912 + 'px';
                    hun.style.left = table_width * 0.471698113208 + 'px';

                    if(foot.style.height != '0px' || t === 0) {
                        clearInterval(styler);
                    }


                    //console.log(foot.style.height);

                }, 200);

            })


        },
        computed : {
            data () {
                return this.$store.state.data;
            },
            activeQuestion () {
                return this.$store.state.current_question;
            },
            activeStep() {
                return this.$store.state.current_step;
            },
            carbon_footprint() {
                return this.$store.state.carbon_footprint;
            },
            is_end() {
                return this.$store.state.is_end;
            },
            modal() {
                return this.$store.state.modal;
            },
            question(){
                return this.data.steps[this.activeStep].questions[this.activeQuestion];
            },
            tons(){
                let ton = this.$store.state.carbon_footprint / 1000;
                return Math.round(ton);
            },
            csv_lines () {
                return this.$store.state.count;
            }
        },
        methods: {
            csv(){
                this.$store.dispatch('check_csv');
            },
            reset(){
              this.$store.dispatch('reset');
            },
            setCookie(){
              this.$cookies.set('age_gate', 0);
            }
        }
    }
</script>
