<template>
        <div id="quiz" :class="{ modal_active : show_modal }" class="screen"  v-bind:style="{ backgroundImage: 'url(' + question.img + ')' }">
            <header class="container">
                <img src="../assets/futmentes_varos_logo.svg" alt="" class="logo">
                <h1>&nbsp;</h1>
            </header>

            <div class="container" v-if="!user_screen">
                <div class="quiz-container" >
                    <QuizHeader/>
                    <div  class="quiz-body">
                        <div class="question">

                            <button class="show-more" v-on:click="open_modal">Tudj meg többet a kérdésről</button>
                            <h2>
                                {{data.steps[activeStep].questions[activeQuestion].name}}
                                <small>
                                    {{data.steps[activeStep].questions[activeQuestion].help}}
                                </small>
                            </h2>

                            <ul class="answers">
                                <li v-bind:key="answer"
                                    v-for="(answer, index) in data.steps[activeStep].questions[activeQuestion].ans"
                                    :class="answer.selected ? 'active' : ''"
                                    v-on:click="select(index)">
                                    {{answer.text}}
                                </li>
                            </ul>
                        </div>
                        <div class="actions">
                            <div class="prev button" v-on:click="prev_question">
                                VISSZA az előző kérdésre
                            </div>
                            <div v-if="is_end" class="next button" @click="set_user_screen">
                                Tovább
                            </div>
                            <div v-if="!is_end" :disabled="!is_selected" class="next button" v-on:click="next_question">
                                Következő
                            </div>
                        </div>
                    </div>
                </div>
                <SectionDescription />
            </div>

            <div class="container" v-if="user_screen">
                <div class="quiz-container" >
                    <form @submit.prevent="checkForm" >

                        <div class="inner">
                            <div v-if="errors.length" class="errors">
                                <b>A form hibákat tartalmaz:</b>
                                <ul>
                                    <li v-bind:key="index" v-for="(error, index) in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <!--div class="form-row">
                                <label for="age">Hány éves ?</label>
                                <select v-model="user.age" name="age" id="age">
                                    <option value="18-25">12-25</option>
                                    <option value="25-35">25-35</option>
                                    <option value="35-45">35-45</option>
                                    <option value="45-55">45-55</option>
                                    <option value="55-65">55-65</option>
                                    <option value="65+">65+</option>
                                </select>
                            </div-->
                            <div class="form-row">

                                <label for="locale">Hol él? </label>
                                <select v-model="user.local" name="locale" id="locale">
                                    <option value="főváros">főváros</option>
                                    <option value="megyeszékhely">megyeszékhely</option>
                                    <option value="nagyváros">nagyváros</option>
                                    <option value="kisváros">kisváros</option>
                                    <option value="falu">falu</option>
                                    <option value="község">község</option>
                                </select>

                            </div>
                            <!--div class="form-row">
                                <label for="education">Mi az Ön legmagasabb iskolai végzettsége? </label>
                                <select v-model="user.education" name="education" id="education">
                                    <option value="kevesebb, mint 8 osztály">kevesebb, mint 8 osztály</option>
                                    <option value="8 osztály">8 osztály</option>
                                    <option value="gimnáziumi érettségi">gimnáziumi érettségi</option>
                                    <option value="szak-középiskolai érettségi">szakközépiskolai érettségi</option>
                                    <option value="szakmunkásképző">szakmunkásképző</option>
                                    <option value="főiskolai vagy egyetemi diploma">főiskolai vagy egyetemi diploma</option>
                                </select>
                            </div-->

                            <div class="form-row">
                                <input type="submit" value="Elküld">
                            </div>

                            <div class="form-row">
                                A kutatás és a közvetlen üzletszerzés célját szolgáló név- és lakcímadatok kezeléséről szóló 1995. évi CXIX. törvény 2. § (1) 3. pontja szerint Piackutatás: az érintett fogyasztói szokásának vizsgálata.
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
</template>

<script>

    import QuizHeader from "../components/quiz/QuizHeader";
    import SectionDescription from "../components/quiz/SectionDescription";

    export default {
        name: 'Quiz',
        components: {
            QuizHeader,
            SectionDescription
        },
        data(){
          return {
              user: {
                  age: false,
                  local: false,
                  education: false
              },
              user_screen : false,
              errors: []
          }
        },
        computed : {
            data () {
                return this.$store.state.data;
            },
            activeQuestion () {
                return this.$store.state.current_question;
            },
            activeStep() {
                return this.$store.state.current_step;
            },
            is_end() {
                return this.$store.state.is_end;
            },
            is_selected(){
                let selected = false;
                let answers = this.data.steps[this.activeStep].questions[this.activeQuestion].ans;
                answers.forEach(function(answer){
                    if(answer.selected) {
                        selected = true;
                    }
                });
                return selected;
            },
            show_modal() {
                return this.$store.state.modal;
            },
            question(){
                return this.data.steps[this.activeStep].questions[this.activeQuestion];
            }
        },
        methods: {
            next_question(){
                this.$store.dispatch('next_question');
            },
            prev_question(){
                this.$store.dispatch('prev_question');
            },
            select(index) {
                this.$store.dispatch('select', index);
            },
            open_modal(){
                this.$store.dispatch('toggle_modal');
            },
            set_user_screen() {
              this.$store.commit('set_user', this.user);
                this.user_screen = true;
            },
            checkForm: function (e) {

                this.errors = [];
                /*
                if (!this.user.age) {
                    this.errors.push("Az életkor kitöltése kötelező!");
                }

                 */
                if (!this.user.local) {
                    this.errors.push("A lakóhely kitöltése kötelező!");
                }
                /*
                if (!this.user.education) {
                    this.errors.push("Az iskolai végzettség kitöltése kötelező!");
                }
                */
                if (!this.errors.length) {
                   this.$store.commit('set_user', this.user);
                   e.preventDefault();
                }

                e.preventDefault();
            }
        }
    }
</script>
